import { Box, Button, Drawer, DrawerBody, DrawerOverlay,DrawerContent, DrawerCloseButton, useDisclosure, Heading, Text, Input, Textarea, Stack, FormControl, FormLabel } from "@chakra-ui/react"
import emailjs from 'emailjs-com';
import React, { useState } from 'react';

export default function CallToAction() {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const btnRef = React.useRef()

  const [formData, setFormData] = useState({
    fname: '',
    lname: '',
    email: '',
    store: '',
    brief: '',
  }); //Add store address and phone number

  const [responseMessage, setResponseMessage] = useState('');

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs.send(
      'service_wo67jv2',
      'template_t8och38',
      formData,
      'C3C17ZEDmcmpIEaiq',
    ).then((response) => {
      setResponseMessage('Request sent successfully!');
      setFormData({ fname: '', lname: '', email: '', store: '', brief: '' });
    }).catch((error) => {
      setResponseMessage('There was an error sending your message. Please try again later.');
    });
  };
  return (
    <>
      <Button width="150px" mt={4} paddingX={5} paddingY={6} variant='solid' colorScheme='green' ref={btnRef} onClick={onOpen} role="button" >Request Quote</Button>
      <Drawer
        isOpen={isOpen}
        placement='right'
        onClose={onClose}
        finalFocusRef={btnRef}
        size="lg"
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerBody>
            <Box maxWidth="500px"  my={20} mx="auto" textAlign="center">
            <Heading as="h2">Request A Free Quote</Heading>
            <Text mt={2}>Whether it's customizing your Shopify store, fixing a bug, or building in new key features, I would be happy to be of assistance.</Text>
            <Text mt={2}>Fill out and submit the form below to receive a free quote with detailed information on how I can help achieve your goals. </Text>
            <form onSubmit={handleSubmit} style={{margin: "1em 0"}}>
              {/* border 2px, color whiteAlpha-400, bg gray-700, mb 10px */}
              {responseMessage && <Heading as='h4' textDecoration='underline' mb={2}>{responseMessage}</Heading>}
              <Stack direction={['column', 'row']} spacing={2}>
                <FormControl id="fname" isRequired>
                  <FormLabel>First Name:</FormLabel>
                  <Input 
                    placeholder='Annabelle' 
                    size='md' 
                    type="text"
                    name="fname"
                    value={formData.fname}
                    onChange={handleChange}
                    variant='outline'
                  />
                </FormControl>
                <FormControl id="lname" isRequired>
                  <FormLabel>Last Name:</FormLabel>
                  <Input 
                    placeholder='Leigh' 
                    size='md' 
                    type="text"
                    name="lname"
                    value={formData.lname}
                    onChange={handleChange}
                    variant='outline'
                  />
                </FormControl>
              </Stack>
              <Stack direction={['column', 'row']} spacing={2} mt={2}>
                <FormControl id="email" isRequired>
                  <FormLabel>Email Address</FormLabel>
                  <Input 
                    placeholder="email@domain.com"
                    size='md'
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    variant='outline'
                  />
                </FormControl>
                <FormControl id="store" isRequired>
                  <FormLabel>Store URL</FormLabel>
                  <Input
                    type="url" 
                    size='md' 
                    name="store" 
                    value={formData.store} 
                    onChange={handleChange} 
                    variant='outline' 
                    placeholder="https://www.yourstore.com" 
                  />
                </FormControl>
              </Stack>
              
              <FormControl id="brief" isRequired mt={2}>
                <FormLabel>Project Brief</FormLabel>
                <Textarea
                  placeholder='Tell us about your project and please try to be as detailed as possible. This helps us provide you with the best possible solution.'
                  size='md'
                  resize='none' 
                  name="brief"
                  value={formData.brief}
                  onChange={handleChange}
                  variant='outline'
                  h='150px'
                />
              </FormControl>
              <Button variant='solid' colorScheme='green' type="submit" style={{marginTop: "1em"}}>Send Request</Button>
              {responseMessage && <p>{responseMessage}</p>}
            </form>
          </Box>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  )
}