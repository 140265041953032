import React from 'react';
import { Box, Button, Image, UnorderedList, Menu, MenuButton, MenuList, MenuItem, IconButton, Link } from "@chakra-ui/react";
import { HamburgerIcon, ViewIcon, EmailIcon, StarIcon } from "@chakra-ui/icons";
import logo from '../imgs/logo2.png'
// import HubspotForm from "../components/ChallengeFormHubspot";
import Contact from '../components/Contact';
import CallToAction from './CTADrawer';


function RegularNav() {
  return (
    <Box className="menu" id="menu" display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" width="100%" maxW="1200px" mx="auto">
      {/* Logo */}
      <Box width={150}>
        <Link as="a" href="/"><Image src={logo} fallbackSrc='https://via.placeholder.com/150x50' title="Logo" /></Link>
      </Box>
      {/* Desktop Nav */}
      <Box className="desktop-nav" alignItems="center" >
        <UnorderedList marginInlineStart={0} role='navigation'>
          <li><Link as="a" href="#about">About</Link></li>
          <li><Link as="a" href="#services">Services</Link></li>
          <li><Link as="a" href="#portfolio">Portfolio</Link> </li>
          {/* <li><Link as="a" href="#team">Team</Link></li> */}
          {/* <li>Pricing</li> */}
          {/* <li>FAQs</li> */}
          {/* <li>Resources</li> */}
          {/* <li><Link as="a" href="#contact">Contact</Link></li> */}
        </UnorderedList>
      </Box>
      <Box width={150} textAlign='right' className="desktop-cta">
        {/* <Link as="a" href="#contact">
          <Button width="150px" mt={4} paddingX={5} paddingY={6} variant='solid' colorScheme='green'>Contact</Button>
        </Link> */}
        <CallToAction />
      </Box>
      {/* Mobile nav */}
      
      <Menu>
        <MenuButton
          as={IconButton}
          aria-label='Options'
          icon={<HamburgerIcon />}
          variant='outline' className="mobile-nav"
          style={{border: "2px solid white"}}
        />
        <MenuList role='navigation'>
          <MenuItem icon={<ViewIcon />} as='a' href='#about'>
            About
          </MenuItem>
          <MenuItem icon={<ViewIcon />} as='a' href='#portfolio'>
            Portfolio
          </MenuItem>
          <MenuItem icon={<StarIcon />} as='a' href='#services'>
            Services
          </MenuItem>
          {/* <MenuItem icon={<ViewIcon />} as='a' href='#portfolio'>
            Team
          </MenuItem>
          <CallToAction /> */}
        </MenuList>
        
      </Menu>
    </Box>
  );
}

export default RegularNav;