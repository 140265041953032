import { Box, Text, Button, HStack, Avatar, Wrap, WrapItem, SimpleGrid, Heading, Image, Flex } from "@chakra-ui/react";
import RegularNav from "./RegularNav";
import ScrollAnimation from "./ScrollAnimation";
import Headshot from "../imgs/headshot.png"
import Shopify from '../imgs/shopify/shopify.png'
import ShopifyPartner from "../imgs/shopify/shopify-partners.png"
import ShopifyPlus from '../imgs/shopify/shopify-plus.png'
import ShopifyPlusExperts from "../imgs/shopify/shopify-plus-experts.png"

function Hero() {
  return (
    <Box className="hero" padding="10px" mx="auto" id="hero" display="flex" flexDirection="column" justifyContent="space-between" aria-label="">
      <RegularNav />
      <Box maxW="1200px" my={10} mx="auto" mt='3rem' minHeight="50vh" className="content" display="flex" alignItems="center" justifyContent="center" flexDirection="column" p={10}>
        <Avatar size='3xl' name='F' src={Headshot} style={{border: "2px solid white"}} mb={5} maxWidth={180} />
        <Text className="title"  fontSize="5xl" bgClip="text" color='rgb(255 255 255 / 85%)' maxW={700} textAlign="center" lineHeight="1em" mb={5} >Expert Shopify Developement Help & Support When You Need It</Text>
        <Text className="subtitle" fontSize="2xl" bgClip="text" color='rgb(255 255 255 / 85%)' maxW={700} textAlign="center">Hi, my name is Francis, and I provide on-demand Shopify storefront design, development, optimization, and support to help brands boost sales and scale quickly.</Text>
        <HStack>
          <Button as='a' mt={4} paddingX={10} paddingY={6} variant='solid' href='#services' style={{border: '1px solid white'}}>See Services</Button>
        </HStack>
      </Box>
      {/* <Flex maxW="700px" width='90%' mt={5}className="logos" margin="0 auto" maxWidth='90%' flexDirection='row' wrap='wrap' justifyContent='center' gap={5} p={10}> */}
        {/* <ScrollAnimation /> */}
        {/* <Image src={Shopify} alt="Shopify" style={{backgroundColor: "rgba(255, 255, 255, 0.7)", padding: '5px', height: "50px", borderRadius: '0.7rem'}} />
        <Image src={ShopifyPlus} alt="Shopify Plus" style={{backgroundColor: "rgba(255, 255, 255, 0.7)", padding: '5px', height: "50px", borderRadius: '0.7rem'}} />
        <Image src={ShopifyPartner} alt="Shopify Partners" style={{backgroundColor: "rgba(255, 255, 255, 0.7)", padding: '5px', height: "50px", borderRadius: '0.7rem'}} />
        <Image src={ShopifyPlusExperts} alt="Shopify Plus Experts" style={{backgroundColor: "rgba(255, 255, 255, 0.7)", padding: '5px', height: "50px", borderRadius: '0.7rem'}} />
      </Flex> */}
      <SimpleGrid columns={{ sm: 1, md: 2, lg: 4 }} spacing='8' textAlign='center' rounded='lg' p={10} maxW="1200px" mx="auto">
        <Box boxShadow='lg' p='6' rounded='md' _hover={{boxShadow:'xl',}} bg='blackAlpha.700'  border={1} display='flex' flexDirection='column' alignItems='center' style={{borderRadius: '25px', border: '1px solid rgba(255, 255, 255, 0.7)'}} tabindex="0">
          <Heading as="h4">FASTER.</Heading>
          <Text>We complete your project <strong>in record time</strong> and stick to agreed timelines. What would take others around 3 weeks, we complete in about 10 days.</Text>
        </Box>
        <Box boxShadow='lg' p='6' rounded='md' _hover={{boxShadow:'xl',}} bg='blackAlpha.700'  border={1} display='flex' flexDirection='column' alignItems='center' style={{borderRadius: '25px', border: '1px solid rgba(255, 255, 255, 0.7)'}} tabindex="0">
          <Heading as="h4">BETTER.</Heading>
          <Text>We have in-depth experience from working with dozens of other businesses. We know exactly what you need and how to implement it to <strong>best suit your needs</strong>.</Text>
        </Box>
        {/* <Box boxShadow='lg' p='6' rounded='md' _hover={{boxShadow:'xl',}} bg='blackAlpha.700'  border={1} display='flex' flexDirection='column' alignItems='center' style={{borderRadius: '25px', border: '1px solid rgba(255, 255, 255, 0.7)'}} tabindex="0">
          <Heading as="h4">BUDGET FRIENDLY.</Heading>
          <Text>Yes, we are way <strong>cheaper</strong> and not ashamed to say it. We spend way less time due to our combined expertise and so we charge you less.</Text>
        </Box>
        <Box boxShadow='lg' p='6' rounded='md' _hover={{boxShadow:'xl',}} bg='blackAlpha.700'  border={1} display='flex' flexDirection='column' alignItems='center' style={{borderRadius: '25px', border: '1px solid rgba(255, 255, 255, 0.7)'}} tabindex="0">
          <Heading as="h4">OPEN LOOP.</Heading>
          <Text>We keep you in the loop with frequent <strong>daily</strong> in touch in every step of the process, from discovery to delivery and post-service support.</Text>
        </Box> */}
        <Box boxShadow='lg' p='6' rounded='md' _hover={{boxShadow:'xl',}} bg='blackAlpha.700'  border={1} display='flex' flexDirection='column' alignItems='center' style={{borderRadius: '25px', border: '1px solid rgba(255, 255, 255, 0.7)'}} tabindex="0">
          <Heading as="h4">ACCESSIBLE.</Heading>
          <Text>Aside from great user experience, clean and beautiful UI, and device compatibility, we also <strong>ensure everything we do for you is compliant with relevant standards</strong>.</Text>
        </Box>
        <Box boxShadow='lg' p='6' rounded='md' _hover={{boxShadow:'xl',}} bg='blackAlpha.700'  border={1} display='flex' flexDirection='column' alignItems='center' style={{borderRadius: '25px', border: '1px solid rgba(255, 255, 255, 0.7)'}} tabindex="0">
          <Heading as="h4">TAX DEDUCTIBLE.</Heading>
          <Text>Most of the services we render (especially accessibility related ones) are <strong>fully tax deductible</strong> so when tax time comes, you can write them off.</Text>
        </Box>
      </SimpleGrid>
    </Box>
  );
}

export default Hero;

//. . .

//Royalty free video...for background or as an aside?
// Using as an aside might imply that I'm the person in the video(?) so best to use it as a background instead?